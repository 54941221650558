import { graphql } from 'gatsby';
import React, { useRef } from 'react';

import { Layout } from '../../containers/layout';
import { Modal } from '../../containers/modal';
import { IndexPageTemplate } from '../../pageTemplates';
import { GraphQLData } from '../../types';

interface IndexProps {
  data: GraphQLData;
}

const IndexPage = ({ data }: IndexProps) => {
  return (
    <Layout>
      <IndexPageTemplate data={data} />
      <Modal services />
      <Modal team level="2" />
    </Layout>
  );
};

export const query = graphql`
  query IndexEN {
    strapi {
      landingPage {
        localizations {
          meta {
            title
            description
          }
          id
          subheader
          cover {
            alternativeText
            url
            file {
              ...Image
            }
          }
          methodsTitle
          methodsSubtitle
          disciplineTitle
          disciplineSubtitle
          praxisTitle
          praxisDescription
          disciplines {
            id
            order
            title
            link
          }
          methods {
            id
            title
            link
            order
          }
        }
      }
    }
  }
`;

export default IndexPage;
